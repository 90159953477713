<div class="astrea-menu-mobile">

    <div class="sidenav-mobile" fxFlex="100%" fxLayout="column" fxLayoutAlign="start stretch">

        <div class="d-flex j-c-between a-i-center">
            <img class="img-responsive m-0" src="../../../../assets/images/logos/av_isologo_white.svg" alt="{{ 'logo' | translate }}">
            <button class="menu-close" (click)="clickHandleMenu()" mat-icon-button attr.aria-label="{{ 'close' | translate }}">
                <mat-icon>clear</mat-icon>
            </button>
        </div>

        <mat-nav-list class="pt-0 px-2 mt-3">

            <div>
                <a routerLink="/searcher" (click)="clickHandleMenu()" title="{{ 'navigation.search' | translate }}" mat-menu-item>
                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.search' | translate }}">search</mat-icon>
                    {{ 'navigation.search' | translate }}
                </a>
                <a routerLink="/catalog" (click)="clickHandleMenu()" title="{{ 'navigation.catalog' | translate }}" mat-menu-item>
                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.catalog' | translate }}">collections</mat-icon>
                    {{ 'navigation.catalog' | translate }}
                </a>
                <a *ngIf="(isLoggedIn$ | async) && !isUserShared" routerLink="/library" (click)="clickHandleMenu()" title="{{ 'navigation.library' | translate }}" mat-menu-item>
                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.library' | translate }}">book</mat-icon>
                    {{ 'navigation.library' | translate }}
                </a>
                <a *ngIf="(isLoggedIn$ | async) && isUserAdmin" routerLink="/cpanel" (click)="clickHandleMenu()" title="{{ 'navigation.administrator' | translate }}" mat-menu-item>
                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.administrator' | translate }}">insert_chart</mat-icon>
                    {{ 'navigation.administrator' | translate }}
                </a>
            </div>

            <hr>

            <div *ngIf="(isLoggedIn$ | async); else navBarLogout">
                <a routerLink="/profile/personal" (click)="clickHandleMenu()" title="{{ 'navigation.profile' | translate }}" mat-menu-item>
                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.profile' | translate }}">account_circle</mat-icon>
                    {{ 'navigation.profile' | translate }}
                </a>
                <a *ngIf="!isUserShared" routerLink="/history/searches" (click)="clickHandleMenu()" title="{{ 'navigation.history' | translate }}" mat-menu-item>
                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.history' | translate }}">history</mat-icon>
                    {{ 'navigation.history' | translate }}
                </a>
                <a *ngIf="!isUserShared" routerLink="/subs" (click)="clickHandleMenu()" title="{{ 'navigation.subs' | translate }}" mat-menu-item>
                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.subs' | translate }}">star</mat-icon>
                    {{ 'navigation.subs' | translate }}
                </a>
                <a (click)="logout()" (keydown.enter)="logout()" title="{{ 'navigation.logout' | translate }}" mat-menu-item>
                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.logout' | translate }}">open_in_new</mat-icon>
                    {{ 'navigation.logout' | translate }}
                </a>
            </div>

            <ng-template #navBarLogout>
                <a [routerLink]="['/login']" [queryParams]="{ redirect: redirect }" (click)="clickHandleMenu()" title="{{ 'navigation.login' | translate }}" mat-menu-item>
                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.login' | translate }}">launch</mat-icon>
                    {{ 'navigation.login' | translate }}
                </a>
                <a [routerLink]="['/register']" [queryParams]="{ redirect: redirect }" (click)="clickHandleMenu()" title="{{ 'navigation.register' | translate }}" mat-menu-item>
                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.register' | translate }}">account_circle</mat-icon>
                    {{ 'navigation.register' | translate }}
                </a>
            </ng-template>

        </mat-nav-list>
    </div>

</div>
