import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu'
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { languageAction, emitAlert } from '@shared/redux/actions/actions-store-state.model';
import { Observable, Subscription, of } from 'rxjs';

@Component({
  selector: 'astrea-menu-navbar',
  templateUrl: './menu-navbar.component.html',
  styleUrls: ['./menu-navbar.component.scss']
})
export class MenuNavbarComponent implements OnInit, OnDestroy {

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @Output() handleMenu = new EventEmitter<any>();

  public isLoggedIn$!: Observable<boolean>;
  private auth$!: Subscription;

  public language = 'es-ar';
  private language$!: Subscription;

  public isUserShared = false;
  public isUserAdmin = false;
  public userName!: string;
  private subsUser$!: Subscription;

  public redirect = '/searcher';

  // Parametro auxiliares para redireccionar al /reader con ?page=xxx
  public readerParams: any = {};

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private authFirebaseService: AuthFirebaseService,
    private _t: TranslateService,
  ) { }

  ngOnInit(): void {
    this.setUserClaims();
    this.userIsLoggedIn();
    this.language$ = this.store.select(state => state.storeConfig.language)
      .subscribe(lang => {
        if (this.language != lang) {
          this.language = lang;
          this.handleLanguage();
        }
      });
    this.route.queryParams
      .subscribe(async (params: Params) => {
        this.redirect = params.redirect ? params.redirect : '/searcher';
        if (params.page) this.readerParams.page = params.page;
        // El 'redirect' se setea en el Guard, con el 'bookcode' lo pisamos y redireccionamos desde el backend a un código (para usuarios de entidad)
        if (params.bookcode) this.redirect = `reader/${params.bookcode}`;
      });
  }

  ngOnDestroy(): void {
    if (this.auth$) this.auth$.unsubscribe();
    if (this.language$) this.language$.unsubscribe();
    if (this.subsUser$) this.subsUser$.unsubscribe();
  }


  userIsLoggedIn() {
    this.auth$ = this.authFirebaseService.isLoggedIn()
      .subscribe(user => {
        this.isLoggedIn$ = of(user === null ? false : true);
      });
  }

  setUserClaims(): void {
    this.subsUser$ = this.store.select(state => state.storeUser)
      .subscribe(userData => {
        if (userData.user) {
          const entityuser = userData.user.entityuser;
          this.userName = userData.user.firstname ? userData.user.firstname : this._t.instant('navigation.menu');
          this.isUserShared = (entityuser == 'IP' || entityuser == 'FOREIGN') ? true : false;
          this.isUserAdmin = (userData.user.permissions.includes('ADMINISTRATOR') || entityuser == 'ENTITY_ADMIN') ? true : false;
        }
      });
  }

  handleMenuClose(): void {
    this.handleMenu.emit();
  }

  handleLanguage(): void {
    setTimeout(() => {
      switch (this.language) {
        case 'es-ar' : {
          this._t.use('es-ar');
          this.store.dispatch(languageAction({ language: 'es-ar' }));
          this.alertLanguageChange('El lenguaje cambió a Español');
        };break;
        case 'en-us' : {
          this._t.use('en-us');
          this.store.dispatch(languageAction({ language: 'en-us' }));
          this.alertLanguageChange('The language changed to English');
        };break;
        default : {
          this._t.use('es-ar');
          this.store.dispatch(languageAction({ language: 'es-ar' }));
          this.alertLanguageChange('El lenguaje cambió a Español');
        };break;
      }
    }, 1)
  }

  logout(): void {
    this.authFirebaseService.logout();
  }

  alertLanguageChange(message: string): void {
    this.store.dispatch(emitAlert({
      message: message,
      alertType: 'message'
    }));
  }

}
