<footer class="astrea-footer p-2 px-md-4">

    <hr class="m-0">

    <div>
        <div
            class="py-2" fxFlex="100%"
            fxLayoutAlign="center center" fxLayoutAlign.gt-sm="space-between center" fxLayoutAlign.gt-xs="space-between center"
            fxLayout="column wrap" fxLayout.gt-sm="column wrap" fxLayout.gt-xs="column wrap"
        >
            <div class="footer-menu d-flex j-c-center a-i-center flex-wrap py-2">
                <div class="d-flex j-c-center a-i-center">
                    <a href="https://www.astrea.com.ar" target="_blank" title="astrea.com.ar">
                        astrea.com.ar
                    </a>
                    <a routerLink="/about" class="t-center" title="{{ 'footer.about' | translate }}">
                        {{ 'footer.about' | translate }}
                    </a>
                    <a routerLink="/pricing" title="{{ 'footer.pricing' | translate }}">
                        {{ 'footer.pricing' | translate }}
                    </a>
                </div>
                <div class="d-flex j-c-center a-i-center">
                    <a routerLink="/contact" title="{{ 'footer.contact' | translate }}">
                        {{ 'footer.contact' | translate }}
                    </a>
                    <a routerLink="/roadmap" title="Roadmap">
                        Roadmap
                    </a>
                    <a routerLink="/terms" class="t-center" title="{{ 'footer.terms' | translate }}">
                        {{ 'footer.terms' | translate }}
                    </a>
                </div>
            </div>
            <div class="d-flex j-c-between a-i-center py-2">
                <div class="d-flex j-c-start a-i-center">
                    <p><strong>{{ 'contact.social' | translate }}</strong></p>
                    <a class="footer-social" href="https://www.facebook.com/EditorialAstrea" target="_blank">
                        <img src="../../../../../assets/images/social/icon-fb.svg" width="30px" height="30px" alt="Astrea Facebook">
                    </a>
                    <a class="footer-social" href="https://www.instagram.com/EditorialAstrea" target="_blank">
                        <img src="../../../../../assets/images/social/icon-ig.svg" width="30px" height="30px" alt="Astrea Instagram">
                    </a>
                    <a class="footer-social" href="https://twitter.com/EditorialAstrea" target="_blank">
                        <img src="../../../../../assets/images/social/icon-tw.svg" width="30px" height="30px" alt="Astrea Twitter">
                    </a>
                </div>
                <div>
                    <p class="t-center">{{ 'footer.astrea' | translate }} © {{ currentYear }} {{ 'footer.rights' | translate }}</p>
                </div>
            </div>
        </div>
    </div>

</footer>
