<div class="astrea-faq">
    <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ faq.question }}
            </mat-panel-title>
            <mat-panel-description>
                <mat-icon>{{ panelOpenState ? 'remove' : 'add' }}</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <p>
            {{ faq.answer }}
        </p>
    </mat-expansion-panel>
</div>
