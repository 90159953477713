import { Component, Input } from '@angular/core';
import { FAQ } from '@shared/data-structure/interfaces';

@Component({
  selector: 'astrea-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

  @Input() faq!: FAQ;
  public panelOpenState = false;

  constructor() { }

}
