import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

interface Metadata {
  title: string;
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class SeoMetadataService {

  // https://stackoverflow.com/questions/48330535/dynamically-add-meta-description-based-on-route-in-angular
  // https://seoalive.com/meta-tags/

  constructor(
    private meta: Meta,
    private title: Title,
  ) { }

  setMetadata(meta: Metadata) {

    this.title.setTitle(meta.title);
    this.meta.updateTag({ property: 'og:title', content: meta.title });

    this.meta.updateTag({ name: 'description', content: meta.description });
    this.meta.updateTag({ property: 'og:description', content: meta.description });
  }

}
