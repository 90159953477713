/* *******************************************************
    SERVICIO DE FUNCIONES AUXILIARES Y OTROS EXPORTS
******************************************************* */

import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  // Cantidad de libros total en AV
  public totalBooks!: number | null;

  constructor(
    private store: Store<AppState>,
    private _t: TranslateService
  ) {
    this.store.select(state => state.storeDataProvider.totalBooks)
      .subscribe(total => {
        this.totalBooks = total;
      });
  }


  /* ********** VALIDAR CONTRASEÑAS ********** */

  static validatePassword(control: AbstractControl) {
    const pass = control.value;
    if (!containsNumber(pass) || !containsLetter(pass)) {
      return { invalid_password: true };
    }
    return null;
  }


  /* ********** MATCH DE CONTRASEÑAS E EMAILS ********** */

  static matchPasswords(control: AbstractControl) {
    const password = control.get('newPass')!.value;
    const confirmPassword = control.get('confirmPass')!.value;
    if (password === confirmPassword) {
      return null;
    }
    return { match_password: true };
  }

  static matchUser(control: AbstractControl) {
    const user = control.get('user')!.value;
    const confirmUser = control.get('confirmUser')!.value;
    if (user === confirmUser) {
      return null;
    }
    return { match_user: true };
  }


  /* ********** FECHAS ********** */

  // Obtener fecha de hoy
  static getCurrentDate() {
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }


  /* ********** NUMBERS ********** */

  getOrdinalNumber(edition: number): string {

    let n = edition;
    if (n > 10) {
      // Si tiene dos dígitos, tomamos el último solamente
      const l = edition.toString();
      n = parseInt(l.split('')[l.toString().length-1]);
    }

    if (this._t.currentLang == 'en-us') {
      switch(n) {
        case 1 : { return 'st'; }
        case 2 : { return 'nd'; }
        case 3 : { return 'rd'; }
        case 4 : case 5 : case 6 : case 7 : case 8 : case 9 : case 10 : { return 'th'; }
        default : { return ''; }
      }
    } else {
      if (n) return 'a';
      else return '';
      /* switch(n) {
        case 1 : { return 'ra'; }
        case 2 : { return 'da'; }
        case 3 : { return 'ra'; }
        case 4 : case 5 : case 6 : { return 'ta'; }
        case 7 : { return 'ma'; }
        case 8 : { return 'va'; }
        case 9 : { return 'na'; }
        case 10 : { return 'ma'; }
        default : { return ''; }
      } */
    }

  }

  getTotalBooks(): string {
    return this.getFormatNumber(this.totalBooks);
  }

  getFormatNumber(n: number | null): string {
    // https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
    const lang = this._t.currentLang;
    return n ? new Intl.NumberFormat(lang ? lang : 'es-ar', {}).format(n) : '####';
  }

  currencyFormat(price: string | null): string {
    if (!price)
      return '';
    if (this._t.currentLang == 'en-us')
      return price;
    else
      return price.replace(/,/, '.');
  }

  /* ********** OTROS ********** */

  // Limpiar texto con expresiones regulares
  static clearText(text: string): string {
    let newText = text.replace(/(#[0-9]*)/g, '');
    newText = newText.replace(/[&#+$~%*{}]/g, '');
    newText = newText.replace(/(\/\/\/*)/g, '');
    return newText;
  }

  // Función para decodificar el contenido de los Quotes
  decodeContentBase64(str: string): string {
    // El contenido viene encriptado en Base64, invertido y con 10 caracteres sucios adelante y al final que hay que remover
    const strClean = str.substring(10, str.length).substring(0, str.length-20);
    const strRevert = strClean.split('').reverse().join('');
    return this.decodeUTF8_ANSI(window.atob(strRevert));
  }

  // Función para limpiar caracteres especiales
  decodeUTF8_ANSI(str: string): string {
    return str
      .replace(/Ã‰/g, 'É').replace(/Ã©/g, 'é')
      .replace(/Ã“/g, 'Ó').replace(/Ã³/g, 'ó')
      .replace(/Ãš/g, 'Ú').replace(/Ãº/g, 'ú')
      .replace(/Ã‘/g, 'Ñ').replace(/Ã±/g, 'ñ')
      .replace(/Ã¡/g, 'á')
      .replace(/Âª/g, 'ª').replace(/Â¿/g, '¿')
      .replace(/Ã/g, 'í');
    // Fuente: https://www.indalcasa.com/programacion/html/tabla-de-codificaciones-de-caracteres-entre-ansi-utf-8-javascript-html/
  }

  generateRandomString(num: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let str = '';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
      str += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return str;
  }

}


/* ********** FUNCIONES AUX PARA VALIDAR CONTRASEÑAS ********** */

// Función para saber si un texto contiene una letra
function containsLetter(texto: string){
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  texto = texto.toLowerCase();
  for (let i = 0; i < texto.length; i++) {
    if (letters.indexOf(texto.charAt(i), 0) !== -1){
      return true;
    }
  }
  return false;
}

// Función para saber si un texto contiene un número
function containsNumber(pass: string) {
  const numbers = '0123456789';
  for (let i = 0; i < pass.length; i++){
    if (numbers.indexOf(pass.charAt(i), 0) !== -1){
      return true;
    }
  }
  return false;
}
