import { Component, Input } from '@angular/core';

@Component({
  selector: 'astrea-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  @Input() diameter: number = 40;
  @Input() stroke: number = 4;
  @Input() text: string = '';

  constructor() { }

}
