import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { QuicklinkModule } from 'ngx-quicklink';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '@environments/environment';
import { AppComponent } from './app.component';

const ANGULAR_MODULES = [
  BrowserAnimationsModule,
  AppRoutingModule,
  HttpClientModule,
  QuicklinkModule
];


/* Custom Modules */
import { MaterialModule } from '@material/material.module';
import { SharedModule } from '@shared/shared.module';
import { AngularFireModule } from '@angular/fire/compat';

import { StoreModule as NgRxStoreModule } from '@ngrx/store';
import { reducers } from '@shared/redux/config-store-state.model';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

const CUSTOM_MODULES = [
  SharedModule,
  MaterialModule,
  AngularFireModule.initializeApp(environment.firebaseConfig),
  NgRxStoreModule.forRoot(reducers),
  StoreDevtoolsModule.instrument({
    maxAge: 25,
    logOnly: environment.production
  }),
];


/* Services */
import { AppConfigService, servicesOnRun } from '@shared/services/app-config.service';
import { TokenInterceptor } from '@shared/interceptors/token.interceptor';

/* i18n - Loader de .json */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ANGULAR_MODULES,
    CUSTOM_MODULES,
    TranslateModule.forRoot({
      defaultLanguage: 'es-ar',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: servicesOnRun,
      multi: true,
      deps: [ AppConfigService ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
