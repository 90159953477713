export const environment = {
  production: true,
  env: 'production',
  BASE_URL: 'https://astreavirtual.com',
  URL_API_AV: 'https://astreavirtual-backend.com',
  BASE_URL_S3: 's3.us-south.cloud-object-storage.appdomain.cloud',
  BUCKET_S3: {
    adenda: 'astrea-adenda',
    avatar: 'astrea-avatar',
    images: 'astrea-images',
    index: 'astrea-index',
    prologue: 'astrea-prologue'
  },
  firebaseConfig: {
    apiKey: "AIzaSyAhiD_ZDd4o8XYZG3qUqKW65pSjKCPi8TU",
    authDomain: "astrea-software.firebaseapp.com",
    projectId: "astrea-software",
    storageBucket: "astrea-software.appspot.com",
    messagingSenderId: "363333927876",
    appId: "1:363333927876:web:2e18ace80f77f07c4dda91"
  },
  paypal: {
    clientIdCOL: 'AW3pT61Zm0IEJhO46aYOF2B9XC2oskLkikT8aga950p4iw4uNhN_7gHzxGvGgdScdYdUiExXulOPkkVp'
  }
};
