import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { Observable, Subscription, of } from 'rxjs';

@Component({
  selector: 'astrea-menu-leftnav',
  templateUrl: './menu-leftnav.component.html',
  styleUrls: ['./menu-leftnav.component.scss']
})
export class MenuLeftnavComponent implements OnInit, OnDestroy {

  @Output() handleMenu = new EventEmitter<any>();

  public isLoggedIn$!: Observable<boolean>;
  private auth$!: Subscription;

  public user!: any;
  public isUserShared = false;
  public isUserAdmin = false;
  private subsUser$!: Subscription;

  public redirect = '/searcher';

  // Parametro auxiliares para redireccionar al /reader con ?page=xxx
  public readerParams: any = {};

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private authFirebaseService: AuthFirebaseService,
  ) { }

  ngOnInit(): void {
    this.userIsLoggedIn();
    this.setUserClaims();
    this.route.queryParams
      .subscribe(async (params: Params) => {
        this.redirect = params.redirect ? params.redirect : '/searcher';
        if (params.page) this.readerParams.page = params.page;
        // El 'redirect' se setea en el Guard, con el 'bookcode' lo pisamos y redireccionamos desde el backend a un código (para usuarios de entidad)
        if (params.bookcode) this.redirect = `reader/${params.bookcode}`;
      });
  }

  ngOnDestroy(): void {
    if (this.auth$) this.auth$.unsubscribe();
    if (this.subsUser$) this.subsUser$.unsubscribe();
  }


  userIsLoggedIn() {
    this.auth$ = this.authFirebaseService.isLoggedIn()
      .subscribe(user => {
        this.isLoggedIn$ = of(user === null ? false : true);
      });
  }

  setUserClaims(): void {
    this.subsUser$ = this.store.select(state => state.storeUser)
      .subscribe(userData => {
        this.user = userData.user;
        if (this.user) {
          const entityuser = userData.user.entityuser;
          this.isUserShared = (entityuser == 'IP' || entityuser == 'FOREIGN') ? true : false;
          this.isUserAdmin = (userData.user.permissions.includes('ADMINISTRATOR') || entityuser == 'ENTITY_ADMIN') ? true : false;
        }
      });
  }

  clickHandleMenu(): void {
    this.handleMenu.emit();
  }

  logout(): void {
    this.authFirebaseService.logout();
  }

}
