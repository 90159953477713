import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FOREIGN_TOKEN } from '@shared/data-structure/token-foreign';

@Injectable({
  providedIn: 'root'
})
export class ForeignSesionService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}


  getUserForeign(): string | null {
    if (this.isBrowser)
      return localStorage.getItem(FOREIGN_TOKEN);
    else
      return null;
  }

  setUserForeign(token: string): void {
    if (this.isBrowser)
      localStorage.setItem(FOREIGN_TOKEN, token);
  }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

}
