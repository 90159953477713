<div class="astrea-loading ">
    <div class="d-flex a-i-center flex-wrap flex-column">
        <mat-spinner
            [diameter]="diameter"
            [strokeWidth]="stroke"
        ></mat-spinner>
        <span *ngIf="text">
            {{ text }}
        </span>
    </div>
</div>
