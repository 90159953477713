import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutorizationCPanelGuard implements CanActivate {

  constructor(
    private router: Router,
    private authFirebase: AuthFirebaseService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, currentPath: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.authFirebase.getDataSessionUser()
      .pipe(
        switchMap(user => {
          if (user.claims.permissions.includes('ADMINISTRATOR') || user.claims.entityuser == 'ENTITY_ADMIN')
            return of(true);
          else {
            this.router.navigate(['/searcher']);
            return of(false);
          }
        }),
      );
  }

}
