<div class="astrea-book-simple h-100">
    <div class="astrea-box h-100">

        <div class="d-flex j-c-center a-i-center flex-column p-relative h-100">

            <small *ngIf="roleName" class="book-author-role">
                {{ roleName }}
            </small>

            <div class="astrea-box-active d-flex j-c-around a-i-center flex-column h-100">

                <div class="p-relative w-100">
                    <img class="img-responsive mb-1" [src]="S3Service.getImageURL(book.image)" alt="{{ book.title }}">
                </div>

                <div class="d-flex j-c-between a-i-center flex-column">
                    <h3 class="t-center">{{ book.title }}</h3>
                    <small class="t-center mt-2">
                        <i>{{ 'books.details.year' | translate }}: <strong>{{ book.year ? book.year : '-' }}</strong></i>
                        <i *ngIf="book.pages"> | {{ 'books.details.pages' | translate }}: <strong>{{ book.pages }}</strong></i>
                        <i *ngIf="book.edition"> | {{ 'books.details.edition' | translate }}: <strong>{{ book.edition }}{{ fnService.getOrdinalNumber(book.edition) }}</strong></i>
                    </small>
                </div>

            </div>
            <div class="wrapped-actions w-100 mt-3">

                <button *ngIf="book.useAV; else withoutAV" (click)="bookReader()" class="btn btn-greendark btn-action" mat-button>
                    {{ 'read_now' | translate }}
                </button>
                <ng-template #withoutAV>
                    <small class="t-center">
                        {{ 'books.not_online' | translate }}
                    </small>
                </ng-template>

                <a *ngIf="book.useWeb && book.price && !isPublicDoctrine; else withoutWeb" href="https://www.astrea.com.ar/book/{{ book.bookcode }}"
                    target="_blank" title="{{ book.title }}" class="btn btn-greendark btn-action" mat-button
                >
                    {{ 'buy' | translate }}
                </a>
                <ng-template #withoutWeb>
                    <small class="t-center">
                        {{ 'books.not_buy' | translate }}
                    </small>
                </ng-template>

                <button class="btn btn-greendark btn-action" (click)="bookDetails()" mat-button>
                    {{ 'details' | translate }}
                </button>

            </div>
        </div>

    </div>
</div>
