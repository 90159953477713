import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

interface Page {
  index: number | string;
  value: number | string;
}

@Component({
  selector: 'astrea-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnDestroy {

  public itemsLength!: number;
  public countPages!: number;
  public renderPages: Array<Page> = [];
  private _sendData: Subject<number> = new Subject();

  @Output() onPaginatorChange = new EventEmitter();

  @Input() currentPage = 0;
  @Input() itemsPerPage!: number;
  @Input()
    set totalLength(length: number) {
      this.currentPage = 0;
      setTimeout(() => {
        this.itemsLength = length;
        this.countPages = Math.ceil(this.itemsLength / this.itemsPerPage);
        this.buildPaginator();
      }, 0)
    };

  constructor() { }

  ngOnInit() {
    this.emitChangePage();
  }

  ngOnDestroy() {
    this._sendData.unsubscribe();
  }

  buildPaginator(): void {
    this.renderPages = [];
    if (this.countPages == 0 || this.countPages == 1) {
      // Sin paginador
    }
    else if (this.countPages <= 5) {
      for (let page = 0; page < this.countPages; page++) {
        this.renderPages.push({ index: page, value: page+1 });
      }
    }
    else {
      let ellipsisStart = true;
      let ellipsisEnd = true;
      for (let page = 0; page < this.countPages; page++) {
        // Lógica para contruir el paginador con '...'
        if (page == 0) this.renderPages.push({ index: page, value: page+1 });
        else if (page > 0 && page <= this.countPages-2 && page < this.currentPage+2 && page > this.currentPage-2) this.renderPages.push({ index: page, value: page+1 });
        else if ((page == this.countPages-2 && this.currentPage < 1) || (page == 1 && this.countPages-2 < this.currentPage)) this.renderPages.push({ index: page, value: page+1 });
        else if (page > this.countPages-2 && page < this.countPages) this.renderPages.push({ index: page, value: page+1 });
        else if (ellipsisStart && (page < 3 || this.currentPage >= 3)) {
          ellipsisStart = false;
          this.renderPages.push({ index: '...', value: '...' });
        }
        else if (ellipsisEnd && page == this.countPages-2) {
          ellipsisEnd = false;
          this.renderPages.push({ index: '...', value: '...' });
        }
      }
    }
  }

  changePage(index: any): void {
    this.currentPage = index;
    this._sendData.next(this.currentPage);
    this.buildPaginator();
  }

  previousPage(): void {
    this.currentPage--;
    this._sendData.next(this.currentPage);
    this.buildPaginator();
  }

  nextPage(): void {
    this.currentPage++;
    this._sendData.next(this.currentPage);
    this.buildPaginator();
  }

  emitChangePage() {
    this._sendData
      .pipe(
        debounceTime(750)
      )
      .subscribe(page => {
        this.onPaginatorChange.emit(page+1);
      });
  }

}
