import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { emitAlert, updateUser } from '@shared/redux/actions/actions-store-state.model';
import { ProfileApiService } from '@modules/profile/profile-api.service';
import { DataOption, Country, getDataIVACondition, getDataCountries, getDataProvinces } from '@shared/data-structure/profile-data';

@Component({
  selector: 'astrea-profile-invoicing',
  templateUrl: './profile-invoicing.component.html',
  styleUrls: ['./profile-invoicing.component.scss']
})
export class ProfileInvoicingComponent {

  public user: any;
  @Input()
    set setUser(u: any) {
      this.user = u;
      this.matchData();
    };

  @Input()
    set isTouch(t: boolean) {
      if (t)
        this.touchSignal();
    }

  public countries!: Country[];
  public provinces!: DataOption[];
  public ivaCondition!: DataOption[];

  public formInvoicing = this.formBuilder.group({
    businessName: ['', [Validators.required]],
    cuit: ['' , [Validators.required]],
    phone: ['', [Validators.required]],
    address: ['', [Validators.required]],
    country: ['', [Validators.required]],
    state: ['', []],                                     // Provincia, solo ARG
    city: ['', [Validators.required]],
    postalCode: ['', [Validators.required]],
    ivaCondition: ['', []],                              // Situación frente al iva, solo ARG
  });
  public loading = false;

  constructor(
    private api: ProfileApiService,
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private _t: TranslateService,
  ) { }

  matchData(): void {
    this.countries = getDataCountries();
    this.provinces = getDataProvinces();
    this.ivaCondition = getDataIVACondition();
    this.formInvoicing.patchValue(this.user);
    this.onCountryChange();
  }

  onCountryChange(): void {
    setTimeout(() => {
      const country = this.formInvoicing.get('country')?.value;
      if (country == 'AR') {
        this.formInvoicing.get('state')?.setValidators([Validators.required]);
        this.formInvoicing.get('ivaCondition')?.setValidators([Validators.required]);
      }
      else {
        this.formInvoicing.get('state')?.clearValidators();
        this.formInvoicing.get('ivaCondition')?.clearValidators();
      }
      this.formInvoicing.get('state')?.updateValueAndValidity();
      this.formInvoicing.get('ivaCondition')?.updateValueAndValidity();
    }, 0);
  }

  submitPersonalData(): void {
    if (this.formInvoicing.valid) {
      this.loading = true;
      this.api.putUser(this.formInvoicing.value)
        .subscribe(res => {
          this.loading = false;
          this.store.dispatch(updateUser({ user: res.result.data }));
          this.dispatchAlertUser();
        });
    }
  }

  dispatchAlertUser(): void {
    this.store.dispatch(emitAlert({
      message: this._t.instant('alert.info_update_ok'),
      alertType: 'success'
    }));
  }

  touchSignal(): void {
    this.formInvoicing.markAllAsTouched();
  }

}
