<div class="astrea-profile-personal">
    <mat-card class="p-2">
        <mat-card-content *ngIf="!isUserShared; else userShared">
            <form [formGroup]="formPersonal">

                <div class="astrea-subtitle my-2">
                    <h2>{{ 'profile.personal.personal' | translate }}</h2>
                    <p>
                        {{ 'profile.text_2' | translate }}
                    </p>
                </div>

                <div class="mt-3 mb-2">
                    <p>{{ 'profile.personal.email' | translate }} <strong> {{ user.email }} </strong></p>
                </div>

                <div class="d-block d-sm-flex j-c-between a-i-center">
                    <mat-form-field appearance="fill" class="mr-0 mr-sm-1">
                        <mat-label>{{ 'profile.personal.name' | translate }}</mat-label>
                        <input type="text" matInput formControlName="firstname">
                        <mat-icon matSuffix>account_circle</mat-icon>
                        <mat-error>{{ 'profile.personal.name_err' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="ml-0">
                        <mat-label>{{ 'profile.personal.lastname' | translate }}</mat-label>
                        <input type="text" matInput formControlName="lastname">
                        <mat-icon matSuffix>account_circle</mat-icon>
                        <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="fill">
                    <mat-label>{{ 'profile.personal.profession' | translate }}</mat-label>
                    <input type="text" matInput formControlName="profession">
                    <mat-icon matSuffix>work</mat-icon>
                    <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>{{ 'profile.personal.birthday' | translate }}</mat-label>
                    <input type="text" matInput formControlName="birthday" placeholder="DD/MM/AAAA">
                    <mat-icon matSuffix>date_range</mat-icon>
                    <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                </mat-form-field>

                <div class="d-block d-sm-flex j-c-between a-i-center">
                    <mat-form-field appearance="fill" class="mr-0 mr-sm-1">
                        <mat-label>Código postal de residencia</mat-label>
                        <input type="text" matInput formControlName="postalCodeResidence">
                        <mat-icon matSuffix>code</mat-icon>
                        <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="ml-0">
                        <mat-label>Dirección de residencia</mat-label>
                        <input type="text" matInput formControlName="addressResidence">
                        <mat-icon matSuffix>room</mat-icon>
                        <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="fill">
                    <mat-label>{{ 'profile.personal.residence' | translate }}</mat-label>
                    <mat-select formControlName="nationality" (valueChange)="onCountryChange()">
                        <mat-option *ngFor="let c of countries" value="{{ c.value }}">{{ c.text }}</mat-option>
                    </mat-select>
                    <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                </mat-form-field>

                <div class="d-block d-sm-flex j-c-between a-i-center">
                    <!-- Provincia de Residencia solo para Argentina -->
                    <mat-form-field *ngIf="formPersonal.get('nationality')!.value == 'AR'" class="mr-0 mr-sm-1" appearance="fill">
                        <mat-label>Provincia de residencia</mat-label>
                        <mat-select formControlName="provinceResidence">
                            <mat-option value="">Seleccionar</mat-option>
                            <mat-option *ngFor="let p of provinces" value="{{ p.value }}">{{ p.text }}</mat-option>
                        </mat-select>
                        <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="ml-0" appearance="fill">
                        <mat-label>Ciudad de residencia</mat-label>
                        <input type="text" matInput formControlName="cityResidence">
                        <mat-icon matSuffix>location_city</mat-icon>
                        <mat-error>{{ 'profile.required_field' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <button *ngIf="!loading" (click)="submitPersonalData()" class="btn btn-greendark mt-3 float-right" mat-button>
                    {{ 'save_changes' | translate }}
                </button>
                <button *ngIf="loading" class="btn btn-greendark mt-3 float-right" mat-button>
                    <astrea-loading></astrea-loading>
                </button>

            </form>
        </mat-card-content>

        <ng-template #userShared>
            <div class="my-2">
                <h2>{{ 'profile.business_account' | translate }}</h2>
            </div>
            <div class="mt-3 mb-2">
                <p>{{ 'profile.without_profile' | translate }}</p>
            </div>
        </ng-template>

    </mat-card>
</div>
