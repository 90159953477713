import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { emitAlert } from '@shared/redux/actions/actions-store-state.model';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { SeoMetadataService } from '@shared/services/seo-metadata.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'astrea-subs-success-mp',
  templateUrl: './subs-success-mp.component.html',
  styleUrls: ['../page-purchases.scss']
})
export class SubsSuccessMpComponent implements OnInit, OnDestroy {

  private subsDarkMode$!: Subscription;
  public darkMode!: boolean;

  public redirectOK = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private seo: SeoMetadataService,
    private authFirebaseService: AuthFirebaseService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe((params: Params) => {
        if (params.status && params.preference_id && params.site_id) {
          this.dispatchAlertOK();
          this.redirectOK = true;
          this.authFirebaseService.logoutWithoutRedirect();
        } else {
          setTimeout(() => {
            this.router.navigate(['/searcher']);
          }, 0);
        }
      });

    this.subsDarkMode$ = this.store.select(state => state.storeConfig.darkMode)
      .subscribe(dk => {
        this.darkMode = dk;
      });

    this.setMetadata();
  }

  ngOnDestroy(): void {
    if (this.subsDarkMode$) this.subsDarkMode$.unsubscribe();
  }


  setMetadata(): void {
    this.seo.setMetadata({
      title: 'Suscripción activada | Astrea Virtual',
      description: 'Pago completado con éxito.'
    });
  }

  dispatchAlertOK(): void {
    setTimeout(() => {
      this.store.dispatch(emitAlert({
        message: 'Suscripción completada',    // Si lo traduzco, se rompe cuando redirecciona desde Mercado Pago
        alertType: 'message'
      }));
    }, 0);
  }

}
