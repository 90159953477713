import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearcherApiService {

  constructor(
    private http: HttpClient,
  ) { }

  public searchDoctrine(data: any, filter: any): Observable<any> {
    return this.http.post(`${environment.URL_API_AV}/api/searcher/doctrine`, data, { params: filter });
  }

  public searchAuthors(filter: any): Observable<any> {
    return this.http.post(`${environment.URL_API_AV}/api/searcher/author`, {}, { params: filter });
  }

  public getBookDetails(bookcode: string): Observable<any> {
    return this.http.get(`${environment.URL_API_AV}/api/doctrine/${bookcode}/details`);
  }

  public getDataAfterSearch(data: any): Observable<any> {
    return this.http.put(`${environment.URL_API_AV}/api/doctrine/after-search`, data);
  }

  public getFile(payload: any): Promise<any> {
    return this.http.post(`${environment.URL_API_AV}/api/files/book/resources`, payload, { responseType: 'blob' }).toPromise();
  }

}
