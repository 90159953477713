<mat-dialog-content class="details-width">
    <div class="book-details" fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-evenly start">

        <div *ngIf="!loading; else astreaLoading" fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">

            <div class="d-flex j-c-start a-i-start">

                <div>
                    <img class="d-none d-md-block mr-2" [src]="S3Service.getImageURL(book.image)" alt="{{ book.title }}" width="150">
                </div>

                <div class="w-100">
                    <div class="d-flex j-c-between a-i-center">
                        <h2>{{ book.title }}</h2>
                        <button (click)="closeModal()" class="btn-close p-0" mat-button>
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                    <h3>{{ book.subtitle }}</h3>
                    <div class="mt-2">
                        <p *ngIf="book.vAuthors && book.vAuthors.length > 0"><strong>{{ 'books.details.authors' | translate }}: </strong>{{ buildAuthorsData() }}</p>
                        <p *ngIf="book.vLM && book.vLM.length > 0"><strong>{{ 'books.details.matter' | translate }}: </strong>{{ buildLMData() }}</p>
                    </div>
                    <div class="mt-3">
                        <a *ngIf="book.hasAdenda" (click)="getFile('adenda', book.bookcode)" class="btn btn-blue btn-book btn-loading" [attr.alt]="'books.files.see_adenda' | translate" mat-button>
                            {{ 'books.files.adenda' | translate }}
                            <astrea-loading *ngIf="loadingFile.loading && loadingFile.type == 'adenda'" [diameter]="15" [stroke]="2"></astrea-loading>
                        </a>
                        <a *ngIf="book.hasIndex" (click)="getFile('index', book.bookcode)" class="btn btn-blue btn-book btn-loading" [attr.alt]="'books.files.see_index' | translate" mat-button>
                            {{ 'books.files.index' | translate }}
                            <astrea-loading *ngIf="loadingFile.loading && loadingFile.type == 'index'" [diameter]="15" [stroke]="2"></astrea-loading>
                        </a>
                        <a *ngIf="book.hasPrologue" (click)="getFile('prologue', book.bookcode)" class="btn btn-blue btn-book btn-loading" [attr.alt]="'books.files.see_prologue' | translate" mat-button>
                            {{ 'books.files.prologue' | translate }}
                            <astrea-loading *ngIf="loadingFile.loading && loadingFile.type == 'prologue'" [diameter]="15" [stroke]="2"></astrea-loading>
                        </a>
                    </div>
                </div>
            </div>

            <div class="d-flex j-c-around a-i-start my-3">
                <ul class="book-info w-100 p-0">
                    <li><p>{{ 'books.details.code' | translate }}: <strong>{{ book.bookcode }}</strong></p></li>
                    <li><p>{{ 'books.details.type' | translate }}: <strong>{{ getBookType(book.bookType) }}</strong></p></li>
                    <li *ngIf="book.edition"><p>{{ 'books.details.edition' | translate }}: <strong>{{ book.edition }}{{ fnService.getOrdinalNumber(book.edition) }}</strong></p></li>
                    <li *ngIf="book.vEditorial"><p>{{ 'books.details.editorial' | translate }}: <strong>{{ book.vEditorial[0].editorial }}</strong></p></li>

                    <li *ngIf="book.year"><p>{{ 'books.details.year' | translate }}: <strong>{{ book.year }}</strong></p></li>
                    <li *ngIf="book.pages"><p>{{ 'books.details.pages' | translate }}: <strong>{{ book.pages }}</strong></p></li>
                    <li *ngIf="book.isbn"><p>ISBN: <strong>{{ book.isbn }}</strong></p></li>
                    <li *ngIf="book.publishDate"><p>{{ 'books.details.publish' | translate }}: <strong>{{ book.publishDate }}</strong></p></li>

                    <li *ngIf="getBookBinding(book.bookBinding)"><p>{{ 'books.details.cover' | translate }}: <strong>{{ getBookBinding(book.bookBinding) }}</strong></p></li>
                    <li *ngIf="book.bookFormat"><p>{{ 'books.details.format' | translate }}: <strong>{{ book.bookFormat }}</strong></p></li>
                </ul>
            </div>

            <div>
                <p *ngIf="book.description" class="text-height">
                    <strong>{{ 'books.details.description' | translate }}: </strong>
                    {{ book.description }}
                </p>
                <p *ngIf="book.summary" class="text-height">
                    <strong>{{ 'books.details.summary' | translate }}: </strong>
                    {{ book.summary }}
                </p>
            </div>

            <div *ngIf="book.useWeb && book.price && !isPublicDoctrine" class="d-flex j-c-start a-i-center mt-2">
                <div>
                    <p class="astrea-price">{{ 'books.details.pricing' | translate }}: <strong>{{ fnService.currencyFormat((book.price | currency:'$':'symbol':'4.0')) }} ARS</strong></p>
                </div>
                <div>
                    <a class="btn btn-greendark btn-book ml-3" [href]="'https://www.astrea.com.ar/book/' + book.bookcode" target="_blank" mat-button>
                        {{ 'buy' | translate }}
                    </a>
                </div>
            </div>

        </div>

        <ng-template #astreaLoading>
            <astrea-loading
                [text]="'books.loading' | translate"
            ></astrea-loading>
        </ng-template>

    </div>
</mat-dialog-content>
