import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/redux/config-store-state.model';
import { emitAlert, updateUser } from '@shared/redux/actions/actions-store-state.model';
import { ProfileApiService } from '@modules/profile/profile-api.service';
import { DataOption, Country, getDataCountries, getDataProvinces } from '@shared/data-structure/profile-data';

@Component({
  selector: 'astrea-profile-personal',
  templateUrl: './profile-personal.component.html',
  styleUrls: ['./profile-personal.component.scss']
})
export class ProfilePersonalComponent {

  public user: any;
  @Input() isUserShared!: boolean;
  @Input()
    set setUser(u: any) {
      this.user = u;
      this.matchData();
    };

  @Input()
    set isTouch(t: boolean) {
      if (t)
        this.touchSignal();
    }

  public countries!: Country[];
  public provinces!: DataOption[];

  public formPersonal = this.formBuilder.group({
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    birthday: ['', [Validators.required]],
    profession: ['', [Validators.required]],

    // Residence
    nationality: ['', [Validators.required]],
    provinceResidence: ['', []],                                                  // Solo ARG
    cityResidence: ['', [Validators.required]],
    postalCodeResidence: ['', [Validators.required]],
    addressResidence: ['', [Validators.required]],
  });
  public loading = false;

  constructor(
    private api: ProfileApiService,
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private _t: TranslateService,
  ) { }

  matchData(): void {
    this.countries = getDataCountries();
    this.provinces = getDataProvinces();
    this.formPersonal.patchValue(this.user);
    this.onCountryChange();
  }

  onCountryChange(): void {
    setTimeout(() => {
      const country = this.formPersonal.get('nationality')?.value;
      if (country == 'AR') {
        this.formPersonal.get('provinceResidence')?.setValidators([Validators.required]);
      }
      else {
        this.formPersonal.get('provinceResidence')?.clearValidators();
      }
      this.formPersonal.get('provinceResidence')?.updateValueAndValidity();
    }, 0);
  }

  submitPersonalData(): void {
    if (this.formPersonal.valid) {
      this.loading = true;
      this.api.putUser(this.formPersonal.value)
        .subscribe(res => {
          this.loading = false;
          this.store.dispatch(updateUser({ user: res.result.data }));
          this.dispatchAlertUser();
        });
    }
  }

  dispatchAlertUser(): void {
    this.store.dispatch(emitAlert({
      message: this._t.instant('alert.info_update_ok'),
      alertType: 'success'
    }));
  }

  touchSignal(): void {
    this.formPersonal.markAllAsTouched();
  }

}
