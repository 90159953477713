import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QuicklinkModule } from 'ngx-quicklink';

const ANGULAR_MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  QuicklinkModule,
];
const ANGULAR_MODULE_EXPORT = [
  TranslateModule
];


/* Custom Modules */
import { MaterialModule } from '@material/material.module';

const CUSTOM_MODULES = [
  MaterialModule,
];


/* Components */
import { ProfileInvoicingComponent } from './components/@profile/profile-invoicing/profile-invoicing.component';
import { ProfilePersonalComponent } from './components/@profile/profile-personal/profile-personal.component';
import { ProfileResidenceComponent } from './components/@profile/profile-residence/profile-residence.component';

import { AlertNotificationComponent } from './components/alert-notification/alert-notification.component';
import { BookDetailsComponent } from './components/book-details/book-details.component';
import { BookSimpleComponent } from './components/book-simple/book-simple.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from './components/footer/footer.component';
import { IframePdfComponent } from './components/iframe-pdf/iframe-pdf.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MenuLeftnavComponent } from './components/menu-leftnav/menu-leftnav.component';
import { MenuNavbarComponent } from './components/menu-navbar/menu-navbar.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SubsSuccessMpComponent } from './components/@purchases/subs-success-mp/subs-success-mp.component';
import { SubsSuccessPpComponent } from './components/@purchases/subs-success-pp/subs-success-pp.component';

const COMPONENTS = [
  ProfileInvoicingComponent,
  ProfilePersonalComponent,
  ProfileResidenceComponent,
  AlertNotificationComponent,
  BookDetailsComponent,
  BookSimpleComponent,
  FaqComponent,
  FooterComponent,
  IframePdfComponent,
  LoadingComponent,
  MenuLeftnavComponent,
  MenuNavbarComponent,
  PaginatorComponent,
  SubsSuccessMpComponent,
  SubsSuccessPpComponent,
];

@NgModule({
  declarations: [
    COMPONENTS,
  ],
  imports: [
    ANGULAR_MODULES,
    CUSTOM_MODULES,
  ],
  exports: [
    COMPONENTS,
    ANGULAR_MODULE_EXPORT,
  ],
  providers: []
})
export class SharedModule { }
