<div class="astrea-paginator mt-4">
    <div class="d-flex j-c-center">
        <div *ngIf="renderPages.length > 0" class="d-flex">

            <a (click)="previousPage()" disable class="paginator-item mr-2"
                [ngClass]="currentPage == 0 ? 'disabled' : ''"
            >
                <span class="d-flex j-c-center a-i-center">
                    <mat-icon>chevron_left</mat-icon>
                </span>
            </a>

            <a *ngFor="let page of renderPages" class="paginator-item d-flex j-c-center a-i-center mr-2"
                [ngClass]="currentPage == page.index ? 'page-active' : ''"
            >
                <span (click)="changePage(page.index)" *ngIf="currentPage == page.index" class="d-flex j-c-center a-i-center">
                    {{ page.value }}
                </span>
                <span (click)="changePage(page.index)" *ngIf="currentPage != page.index && page.value != '...'" class="d-flex j-c-center a-i-center">
                    {{ page.value }}
                </span>
                <span *ngIf="page.value == '...'" class="ellipsis d-flex j-c-center a-i-center">
                    {{ page.value }}
                </span>
            </a>

            <a (click)="nextPage()" class="paginator-item d-flex j-c-center a-i-center"
                [ngClass]="currentPage == countPages-1 ? 'disabled' : ''"
            >
                <span class="d-flex j-c-center a-i-center">
                    <mat-icon>chevron_right</mat-icon>
                </span>
            </a>

        </div>
    </div>
</div>
