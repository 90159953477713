import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BookDetailsComponent } from '@shared/components/book-details/book-details.component';
import { BucketS3Service } from '@shared/services/bucket-s3.service';
import { FunctionsService } from '@shared/services/functions.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '@shared/redux/config-store-state.model';
import { emitAlert } from '@shared/redux/actions/actions-store-state.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { AuthorRoleService } from '@shared/services/author-role.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'astrea-book-simple',
  templateUrl: './book-simple.component.html',
  styleUrls: ['./book-simple.component.scss']
})
export class BookSimpleComponent implements OnInit, OnDestroy {

  @Input() book: any;
  @Input() authorcode!: string | null;

  public isPublicDoctrine = false;
  private reader$!: Subscription;

  public roleName!: string;

  constructor(
    public S3Service: BucketS3Service,
    public fnService: FunctionsService,
    private router: Router,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private authorRoleService: AuthorRoleService,
    private authFirebaseService: AuthFirebaseService,
    private _t: TranslateService
  ) { }

  ngOnInit(): void {
    this.setRole();
    this.isPublicDoctrine = this.book.bookcode ? this.book.bookcode.startsWith('9900') : false;
  }

  ngOnDestroy(): void {
    if (this.reader$) this.reader$.unsubscribe();
  }

  bookReader(): void {
    this.reader$ = this.authFirebaseService.isLoggedIn()
      .subscribe(user => {
        if (!user) {
          this.dispatchRedirectLogin();
          // Si no está logueado, los params se capturan desde login.component
          this.router.navigate(['/login'], { queryParams: { redirect: `reader/${this.book.bookcode}`, page: 1 } });
        } else {
          this.router.navigate([`/reader/${this.book.bookcode}`]);
        }
      });
  }

  bookDetails(): void {
    this.dialog.open(BookDetailsComponent, {
      data: {
        bookcode: this.book.bookcode,
      }
    });
  }

  setRole(): void {
    this.book.authors.forEach((a: any) => {
      if (a.author == this.authorcode)
        this.roleName = this.authorRoleService.getAuthorRole(a.role);
    });
  }

  dispatchRedirectLogin(): void {
    this.store.dispatch(emitAlert({
      message: this._t.instant('alert.reader_login'),
      alertType: 'message'
    }));
  }

}
