import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutorizationBooksGuard implements CanActivate {

  constructor(
    private router: Router,
    private http: HttpClient,
    private authFirebase: AuthFirebaseService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, currentPath: RouterStateSnapshot): Observable<boolean> | boolean {

    return this.authFirebase.getDataSessionUser()
      .pipe(
        switchMap(user => {

          const entityuser = user.claims.entityuser;

          if (user.claims.subscriber || entityuser == 'IP' || entityuser == 'BELONG' || entityuser == 'FOREIGN' || entityuser == 'ENTITY_ADMIN')
            return of(true);
          else {
            // Si no tiene permisos, validamos desde el backend si el usuario alquiló el libro digital
            const bookcode = currentPath.url.replace(/\/*reader\//, '').replace(/\?.*$/, '');
            return this.http.get(`${environment.URL_API_AV}/api/user/me/digital-books/permissions/${bookcode}`)
              .pipe(
                map((res: any) => {
                  if(res.result.hasPermissions)
                    return true;
                  else {
                    this.router.navigate(['/pricing']);
                    return false;
                  }
                }),
              )
          }

        }),
      );

  }

}
