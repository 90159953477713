import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BucketS3Service {

  // PRODUCTION && DEVELOPMENT is the same BASE_URL
  // https://astrea-index.s3.us-south.cloud-object-storage.appdomain.cloud/index-0002504.pdf
  // https://s3.us-south.cloud-object-storage.appdomain.cloud/astrea-index/index-0002504.pdf

  constructor() { }


  /* ==============================
              IMAGES
  ============================== */

  public getAvatarURL(authorcode: string): string {
    return `https://${this.getBucketS3('avatar')}.${environment.BASE_URL_S3}/avatar-${authorcode}.jpg`;
  }

  public getImageURL(filename: string): string {
    return `https://${this.getBucketS3('image')}.${environment.BASE_URL_S3}/${filename}`;
  }


  /* ==============================
              FILES
  ============================== */

  public getAdendaURL(bookcode: string): string {
    return `https://${this.getBucketS3('adenda')}.${environment.BASE_URL_S3}/adenda-${bookcode}.pdf`;
  }

  public getIndexURL(bookcode: string): string {
    return `https://${this.getBucketS3('index')}.${environment.BASE_URL_S3}/index-${bookcode}.pdf`;
  }

  public getPrologueURL(bookcode: string): string {
    return `https://${this.getBucketS3('prologue')}.${environment.BASE_URL_S3}/prologue-${bookcode}.pdf`;
  }

  public getFileUrlByBucket(bucket: string, bookcode: string) {
    if (bucket == 'adenda') {
      return `https://${this.getBucketS3('adenda')}.${environment.BASE_URL_S3}/adenda-${bookcode}.pdf`;
    } else if (bucket == 'index') {
      return `https://${this.getBucketS3('index')}.${environment.BASE_URL_S3}/index-${bookcode}.pdf`;
    } else if (bucket == 'prologue') {
      return `https://${this.getBucketS3('prologue')}.${environment.BASE_URL_S3}/prologue-${bookcode}.pdf`;
    } else {
      throw new Error('Bucket does not exist');
    }
  }

  private getBucketS3(bucket: string): string {
    switch (bucket) {
      case 'adenda' : { return environment.BUCKET_S3.adenda; }
      case 'avatar' : { return environment.BUCKET_S3.avatar; }
      case 'image' : { return environment.BUCKET_S3.images; }
      case 'index' : { return environment.BUCKET_S3.index; }
      case 'prologue' : { return environment.BUCKET_S3.prologue; }
      default : { return ''; }
    }
  }

}
