// DATAPROVIDER triplicado en AV, WEB y BO

export interface DataOption {
  value: string;
  text: string;
}

export interface Country {
  value: string;
  text: string;
  fxZone: string;       // Envios internacionales
  caZone: string;       // Envios Correo Argentino        LIMITROFE | AMERICA | MUNDO
}

export function getDataIVACondition(): DataOption[] {
  const dataIVACondition = [
    { value: '1', text: 'IVA Responsable Inscripto' },
    { value: '2', text: 'IVA Responsable no Inscripto' },
    { value: '3', text: 'IVA no Responsable' },
    { value: '4', text: 'IVA Sujeto Exento' },
    { value: '5', text: 'Consumidor Final' },
    { value: '6', text: 'Responsable Monotributo' },
    { value: '7', text: 'Sujeto no Categorizado' },
    { value: '8', text: 'Proveedor del Exterior' },
    { value: '9', text: 'Cliente del Exterior' },
    { value: '10', text: 'IVA Liberado - Ley N° 19.640' },
    { value: '11', text: 'IVA Responsable Inscripto - Agente de Percepción' },
    { value: '12', text: 'Pequeño Contribuyente Eventual' },
    { value: '13', text: 'Monotributista Social' },
    { value: '14', text: 'Pequeño Contribuyente Eventual Social' }
  ];

  return dataIVACondition;
}

export function getDataProvinces(): DataOption[] {
  const dataProvinces = [
    { value: '1', text: 'Buenos Aires' },
    { value: '2', text: 'Capital Federal' },
    { value: '3', text: 'Catamarca' },
    { value: '4', text: 'Chaco' },
    { value: '5', text: 'Chubut' },
    { value: '6', text: 'Córdoba' },
    { value: '7', text: 'Corrientes' },
    { value: '8', text: 'Entre Ríos' },
    { value: '9', text: 'Formosa' },
    { value: '10', text: 'Jujuy' },
    { value: '11', text: 'La Pampa' },
    { value: '12', text: 'La Rioja' },
    { value: '13', text: 'Mendoza' },
    { value: '14', text: 'Misiones' },
    { value: '15', text: 'Neuquén' },
    { value: '16', text: 'Río Negro' },
    { value: '17', text: 'Salta' },
    { value: '18', text: 'San Juan' },
    { value: '19', text: 'San Luis' },
    { value: '20', text: 'Santa Cruz' },
    { value: '21', text: 'Santa Fe' },
    { value: '22', text: 'Santiago del Estero' },
    { value: '23', text: 'Tierra del Fuego' },
    { value: '24', text: 'Tucumán' }
  ];

  return dataProvinces;
}

export function getDataCountries(): Country[] {
  const dataCountries = [
    { value: "AF", text: "Afganistán", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "AL", text: "Albania", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "DE", text: "Alemania", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "AD", text: "Andorra", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "AO", text: "Angola", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "AI", text: "Anguilla", fxZone: "CA-RM", caZone: "AMERICA" },
    { value: "AQ", text: "Antartica", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "AG", text: "Antigua y Barbados", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "SA", text: "Arabia Saudita", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "DZ", text: "Argelia", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "AR", text: "Argentina", fxZone: "LOCAL", caZone: "Local" },
    { value: "AM", text: "Armenia", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "AW", text: "Aruba", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "AU", text: "Australia", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "AT", text: "Austria", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "AZ", text: "Azerbaiyan", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "BS", text: "Bahamas", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "BH", text: "Bahrain", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "BA", text: "Bangladesh", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "BD", text: "Barbados", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "BE", text: "Belgica", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "BZ", text: "Belize", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "BJ", text: "Benin", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "BM", text: "Bermuda", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "BY", text: "Bielorusia", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "BO", text: "Bolivia", fxZone: "FX-B", caZone: "LIMITROFE" },
    { value: "BW", text: "Botswana", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "BR", text: "Brasil", fxZone: "FX-A", caZone: "LIMITROFE" },
    { value: "BG", text: "Bulgaria", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "BF", text: "Burkina Faso", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "CV", text: "Cabo Verde", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "KH", text: "Camboya", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "CM", text: "Camerun", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "CA", text: "Canada", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "TD", text: "Chad", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "CL", text: "Chile", fxZone: "FX-A", caZone: "LIMITROFE" },
    { value: "CN", text: "China", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "CY", text: "Chipre", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "CO", text: "Colombia", fxZone: "FX-B", caZone: "AMERICA" },
    { value: "KM", text: "Comoros", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "CG", text: "Congo", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "CR", text: "Costa Rica", fxZone: "FX-C", caZone: "MUNDO" },
    { value: "HR", text: "Croacia", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "CU", text: "Cuba", fxZone: "CA-AM", caZone: "AMERICA" },
    { value: "DK", text: "Dinamarca", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "DM", text: "Dominica", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "EC", text: "Ecuador", fxZone: "FX-B", caZone: "AMERICA" },
    { value: "EG", text: "Egipto", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "SV", text: "El Salvador", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "AE", text: "Emiratos Arabes Unidos", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "SI", text: "Eslovenia", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "ES", text: "España", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "US", text: "Estados Unidos", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "EE", text: "Estonia", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "ET", text: "Etiopia", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "FJ", text: "Fiji", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "PH", text: "Filipinas", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "FI", text: "Finlandia", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "FR", text: "Francia", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "GA", text: "Gabon", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "GM", text: "Gambia", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "GE", text: "Georgia", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "GH", text: "Ghana", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "GI", text: "Gibraltar", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "GL", text: "Global", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "GD", text: "Granada", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "GR", text: "Grecia", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "GT", text: "Guatemala", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "GN", text: "Guinea", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "GW", text: "Guinea Bissau", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "GQ", text: "Guinea Ecuatorial", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "GY", text: "Guyana", fxZone: "FX-B", caZone: "AMERICA" },
    { value: "GF", text: "Guyana (Fr.)", fxZone: "FX-B", caZone: "AMERICA" },
    { value: "HT", text: "Haiti", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "NL", text: "Holanda", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "HO", text: "Honduras", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "HK", text: "Hongkong", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "HU", text: "Hungria", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "IN", text: "India", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "IO", text: "Indias Britanicas", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "ID", text: "Indonesia", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "IR", text: "Iran", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "IQ", text: "Iraq", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "IE", text: "Irlanda", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "IS", text: "Islandia", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "KY", text: "Islas Caiman", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "CK", text: "Islas Cook", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "FO", text: "Islas Faroe", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "FK", text: "Islas Malvinas", fxZone: "CA-RM", caZone: "AMERICA" },
    { value: "SB", text: "Islas Salomon", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "IL", text: "Israel", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "IT", text: "Italia", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "JM", text: "Jamaica", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "JP", text: "Japon", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "JO", text: "Jordania", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "KZ", text: "Kazastan", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "KE", text: "Kenia", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "KP", text: "Korea (Norte)", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "KR", text: "Korea (Sur)", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "KW", text: "Kuwait", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "LA", text: "Laos", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "LV", text: "Latvia", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "LS", text: "Lesotho", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "LB", text: "Libano", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "LR", text: "Liberia", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "LY", text: "Libia", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "LI", text: "Liechtenstein", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "LT", text: "Lituania", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "LU", text: "Luxemburgo", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "MO", text: "Macao", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "MK", text: "Macedonia", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "MG", text: "Madagascar", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "MY", text: "Malasia", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "MW", text: "Malawi", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "MV", text: "Maldivas", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "ML", text: "Mali", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "MT", text: "Malta", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "MA", text: "Marruecos", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "MU", text: "Mauricio", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "MX", text: "Mexico", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "FM", text: "Micronesia", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "MD", text: "Moldavia", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "MC", text: "Monaco", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "MN", text: "Mongolia", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "MS", text: "Montserrat", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "MZ", text: "Mozambique", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "NA", text: "Namibia", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "NR", text: "Nauru", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "NP", text: "Nepal", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "NI", text: "Nicaragua", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "NE", text: "Niger", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "NG", text: "Nigeria", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "NU", text: "Niue", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "NO", text: "Noruega", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "NC", text: "Nueva California (Fr.)", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "NZ", text: "Nueva Zelanda", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "OM", text: "Oman", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "PK", text: "Pakistan", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "PW", text: "Palau", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "PA", text: "Panama", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "PG", text: "Papua Nueva Guinea", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "PY", text: "Paraguay", fxZone: "FX-A", caZone: "LIMITROFE" },
    { value: "PE", text: "Peru", fxZone: "FX-B", caZone: "AMERICA" },
    { value: "PF", text: "Polinesia (Fr.)", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "PL", text: "Polonia", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "PT", text: "Portugal", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "PR", text: "Puerto Rico (Us)", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "QA", text: "Qatar", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "UK", text: "Reino Unido", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "CZ", text: "Republica Checa", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "DO", text: "Republica Dominicana", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "SK", text: "Republica Eslovaca", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "RW", text: "Ruanda", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "RO", text: "Rumania", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "RU", text: "Rusia", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "EH", text: "Sahara Del Oeste", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "AS", text: "Samoa", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "WS", text: "Samoa Del Oeste", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "SM", text: "San Marino", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "VC", text: "San Vincente & Granadin", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "SH", text: "Santa Helena", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "LC", text: "Santa Lucia", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "SN", text: "Senegal", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "SL", text: "Sierraleona", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "SG", text: "Singapur", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "SY", text: "Siria", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "SO", text: "Somalia", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "LK", text: "Srilanka", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "ZA", text: "Sudafrica", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "SD", text: "Sudan", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "SE", text: "Suecia", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "CH", text: "Suiza", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "SR", text: "Surinam", fxZone: "FX-B", caZone: "AMERICA" },
    { value: "TH", text: "Tailandia", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "TW", text: "Taiwan", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "TZ", text: "Tanzania", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "TG", text: "Togo", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "TK", text: "Tokelau", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "TO", text: "Tonga", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "TT", text: "Trinidad & Tobago", fxZone: "FX-C", caZone: "AMERICA" },
    { value: "TN", text: "Tunez", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "TM", text: "Turkmenistan", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "TR", text: "Turquia", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "TV", text: "Tuvalu", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "UA", text: "Ucrania", fxZone: "FX-E", caZone: "MUNDO" },
    { value: "UG", text: "Uganda", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "UY", text: "Uruguay", fxZone: "FX-A", caZone: "LIMITROFE" },
    { value: "UZ", text: "Uzbekistan", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "VE", text: "Venezuela", fxZone: "FX-B", caZone: "AMERICA" },
    { value: "VN", text: "Vietnam", fxZone: "FX-D", caZone: "MUNDO" },
    { value: "YE", text: "Yemen", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "YU", text: "Yugoslavia", fxZone: "CA-RM", caZone: "MUNDO" },
    { value: "ZR", text: "Zaire", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "ZM", text: "Zambia", fxZone: "FX-F", caZone: "MUNDO" },
    { value: "ZW", text: "Zimbabwe", fxZone: "FX-F", caZone: "MUNDO" }
  ];

  return dataCountries;
}
