<header class="astrea-menu-navbar">

    <div class="astrea-toolbar d-flex j-c-around a-i-center">

        <div class="d-flex j-c-center a-i-center">
            <a routerLink="/" title="Home" fxHide fxHide.md="false" fxHide.lg="false" fxHide.xl="false">
                <img class="py-1" height="84px" src="../../../../assets/images/logos/av_logo_white.svg" alt="{{ 'logo' | translate }}">
            </a>
            <a routerLink="/" title="Home" fxHide fxHide.sm="false" fxHide.xs="false">
                <img height="52px" src="../../../../assets/images/logos/av_logo_white.svg" alt="{{ 'logo' | translate }}">
            </a>
        </div>


        <div fxHide fxHide.md="false" fxHide.lg="false" fxHide.xl="false">
            <div>
                <a routerLink="/searcher" title="{{ 'navigation.search' | translate }}" class="mx-2">
                    {{ 'navigation.search' | translate }}
                </a>
                <a routerLink="/catalog" title="{{ 'navigation.catalog' | translate }}" class="mx-2">
                    {{ 'navigation.catalog' | translate }}
                </a>
                <a *ngIf="(isLoggedIn$ | async) && !isUserShared" routerLink="/library" title="{{ 'navigation.library' | translate }}" class="mx-2">
                    {{ 'navigation.library' | translate }}
                </a>
                <a *ngIf="(isLoggedIn$ | async) && isUserAdmin" routerLink="/cpanel" title="{{ 'navigation.administrator' | translate }}" class="mx-2">
                    {{ 'navigation.administrator' | translate }}
                </a>
            </div>
        </div>


        <div fxLayoutAlign="end center">

            <div class="astrea-language">
                <select (ngModelChange)="handleLanguage()" name="language" [(ngModel)]="language">
                    <option value="es-ar">ES</option>
                    <option value="en-us">EN</option>
                </select>
            </div>

            <div fxHide fxHide.md="false" fxHide.lg="false" fxHide.xl="false">
                <div *ngIf="(isLoggedIn$ | async); else navBarLogout" >
                    <button class="avDropdown d-flex j-c-center a-i-center ml-3 w-100" [matMenuTriggerFor]="avDropdown">
                        <span mat-icon-button>
                            <mat-icon>persona</mat-icon>
                        </span>
                        <span>{{ isUserShared ? ('navigation.menu' | translate) : userName }}</span>
                    </button>
                    <mat-menu #avDropdown="matMenu">
                        <div class="astrea-profile-dropdown d-flex j-c-between a-i-start flex-wrap flex-column">
                            <mat-nav-list class="w-100">
                                <a routerLink="profile/personal" title="{{ 'navigation.profile' | translate }}" mat-menu-item>
                                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.profile' | translate }}">account_circle</mat-icon>
                                    {{ 'navigation.profile' | translate }}
                                </a>
                                <a *ngIf="!isUserShared" routerLink="history/searches" title="{{ 'navigation.history' | translate }}" mat-menu-item>
                                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.history' | translate }}">history</mat-icon>
                                    {{ 'navigation.history' | translate }}
                                </a>
                                <a *ngIf="!isUserShared" routerLink="/subs" title="{{ 'navigation.subs' | translate }}" mat-menu-item>
                                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.subs' | translate }}">star</mat-icon>
                                    {{ 'navigation.subs' | translate }}
                                </a>
                                <a routerLink="/contact" title="{{ 'navigation.contact' | translate }}" mat-menu-item>
                                    <mat-icon class="mr-2" attr.aria-label="{{ 'navigation.contact' | translate }}">feedback</mat-icon>
                                    {{ 'navigation.contact' | translate }}
                                </a>
                            </mat-nav-list>
                            <a (click)="logout()" (keydown.enter)="logout()" class="btn-logout" title="{{ 'navigation.logout' | translate }}" mat-menu-item>
                                {{ 'navigation.logout' | translate }}
                            </a>
                        </div>
                    </mat-menu>
                </div>
            </div>

            <ng-template #navBarLogout>
                <div class="navBarLogout2 d-flex j-c-center a-i-center">
                    <a [routerLink]="['/register']" [queryParams]="{ redirect: redirect }" title="{{ 'navigation.register' | translate }}">
                        {{ 'navigation.register' | translate }}
                    </a>
                    <a [routerLink]="['/login']" [queryParams]="{ redirect: redirect }" class="mr-3" title="{{ 'navigation.login' | translate }}">
                        {{ 'navigation.login' | translate }}
                    </a>
                </div>
            </ng-template>

        </div>

        <button (click)="handleMenuClose()" fxHide fxHide.sm="false" fxHide.xs="false" mat-icon-button>
            <mat-icon>menu</mat-icon>
        </button>

    </div>
</header>
