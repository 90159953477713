import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'astrea-alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrls: ['./alert-notification.component.scss']
})
export class AlertNotificationComponent implements OnInit {

  public message!: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.message = this.data;
  }

  closeNotification(): void {
    this.snackBar.dismiss();
  }

}
