<div class="m-sm-4 m-2" fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="space-evenly center">

    <div *ngIf="redirectOK; else astreaLoading" class="subs-success" fxFlex.gt-md="25%" fxFlex.gt-sm="50%" fxFlex.gt-xs="70%" fxFlex="100%" fxLayout="column" fxLayoutAlign="center stretch">

        <div class="mb-3">
            <img *ngIf="darkMode" class="img-responsive" src="../../../../../assets/images/logos/av_logo_white.svg" alt="{{ 'logo' | translate }}">
            <img *ngIf="!darkMode" class="img-responsive" src="../../../../../assets/images/logos/av_logo_green.svg" alt="{{ 'logo' | translate }}">
        </div>

        <div class="astrea-title mt-3">
            <h1>{{ 'subs.success.sub_success' | translate }}</h1>
        </div>

        <div class="mt-4 mb-2">
            <p>
                {{ 'subs.success.text_I' | translate }}
                <br>
            </p>
            <p [innerHTML]="'subs.success.text_II' | translate">
            </p>
        </div>

        <a routerLink="/login" mat-button class="btn btn-greendark mt-5">
            {{ 'subs.success.start' | translate }}
        </a>

    </div>

    <ng-template #astreaLoading>
        <astrea-loading class="mt-5"></astrea-loading>
    </ng-template>

</div>
