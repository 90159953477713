import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ForeignSesionService } from '../services/foreign-sesion.service';
import { AuthFirebaseService } from '../services/auth-firebase.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { JWT_TOKEN } from '@shared/data-structure/jwt-token';
import { FOREIGN_TOKEN, FOREIGN_HEADER } from '@shared/data-structure/token-foreign';
import { environment } from '@environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private store: Store,
    private router: Router,
    private authFirebaseService: AuthFirebaseService,
    private foreignSesionService: ForeignSesionService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes(environment.URL_API_AV)) {

      let token: string | null = '';

      if (this.isBrowser)
        token = localStorage.getItem(JWT_TOKEN);

      if (token)
        request = this.addToken(request, token);

      if (this.foreignSesionService.getUserForeign())
        request = this.addTokenForeign(request, this.foreignSesionService.getUserForeign())

      return next.handle(request)
        .pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.handle401Error(request, next);
            } else if (error instanceof HttpErrorResponse && error.status === 403) {
              /* this.closedSessionUser("403 - Forbidden access"); */
              this.authFirebaseService.removeJWT();
              return throwError(error);
            }
            else if (error instanceof HttpErrorResponse && (error.status === 400 || error.status === 404)) {
              return throwError(error);
            }
            else {
              // Otros errores HTTP
              return throwError(error);
            }
          }),
        );

    }
    else {
      // Otras APIs que consume el front: 'securetoken.googleapis.com'
      // Las solicitudes de los json de i18n pasan por aquí
      return next.handle(request)
        .pipe(
          catchError(this.externalHandleError.bind(this)),
        );
    }

  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.authFirebaseService.removeJWT();
      this.refreshTokenSubject.next(null);

      return this.refreshTokenAndRebuildRequest()
        .pipe(
          switchMap((token: any) => {
            if (this.isBrowser)
              localStorage.setItem(JWT_TOKEN, token.access_token);
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token.access_token);
            return next.handle(this.addToken(request, token.access_token));
          })
        );

    } else {
      return this.refreshTokenSubject
        .pipe(
          filter(token => token != null),
          take(1),
          switchMap(jwt => {
            return next.handle(this.addToken(request, jwt));
          })
        );
    }
  }

  // Manejo de errores de APIs de terceros
  private externalHandleError(error: HttpErrorResponse) {

    if (error instanceof HttpErrorResponse && error.status === 400 && error.message === 'INVALID_REFRESH_TOKEN')
      this.closedSessionUser('Ha ocurrido un error, vuelva a ingresar.');
    else
      this.closedSessionUser('Ha ocurrido un error, vuelva a ingresar.');

    return throwError('HTTP Communication Error');
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private addTokenForeign(request: HttpRequest<any>, token: string | null) {
    if (token) {
      const header: any = {};
      header[FOREIGN_HEADER] = token;
      return request.clone({
        setHeaders: header
      });
    } else
      return request
  }

  private refreshTokenAndRebuildRequest(): Observable<any> {
    return this.authFirebaseService.refreshToken();
  }

  private closedSessionUser(message: string) {
    this.authFirebaseService.logout();
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 750);
    setTimeout(() => {
      alert(message);
    }, 1000);
  }

  public get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

}
