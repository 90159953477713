import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthFirebaseService } from '@shared/services/auth-firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(
    private authFirebaseService: AuthFirebaseService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, currentPath: RouterStateSnapshot): Observable<boolean> {
    return this.authFirebaseService.isLoggedIn()
      .pipe(
        map(user => {

          if (currentPath.url.includes('/login') || currentPath.url.includes('/register')) {
            if (user === null) {
              return true;
            }
            else {
              const redirect = route.queryParams.bookcode ? `/reader/${route.queryParams.bookcode}` : '/searcher' ;
              this.router.navigate([redirect]);
              return false;
            }
          }
          else {
            if (user !== null) {
              return true;
            }
            else {
              this.router.navigate(['/login'], { queryParams: { redirect: currentPath.url } });
              return false;
            }
          }

        })
      );
  }

}


@Injectable({
  providedIn: 'root'
})
export class AuthenticationChildGuard implements CanActivateChild {

  constructor(
    private authFirebaseService: AuthFirebaseService,
    private router: Router
  ) { }

  canActivateChild(route: ActivatedRouteSnapshot, currentPath: RouterStateSnapshot): Observable<boolean> {
    return this.authFirebaseService.isLoggedIn()
      .pipe(
        map(user => {

          if (user !== null) {
            return true;
          }
          else {
            this.router.navigate(['/searcher']);
            return false;
          }

        })
      );
  }

}
